exports.components = {
  "component---src-components-ui-post-tsx-content-file-path-posts-ai-goverance-mdx": () => import("./../../../src/components/ui/Post.tsx?__contentFilePath=/vercel/path0/posts/ai-goverance.mdx" /* webpackChunkName: "component---src-components-ui-post-tsx-content-file-path-posts-ai-goverance-mdx" */),
  "component---src-components-ui-post-tsx-content-file-path-posts-aigc-2023-mdx": () => import("./../../../src/components/ui/Post.tsx?__contentFilePath=/vercel/path0/posts/aigc-2023.mdx" /* webpackChunkName: "component---src-components-ui-post-tsx-content-file-path-posts-aigc-2023-mdx" */),
  "component---src-components-ui-post-tsx-content-file-path-posts-breeze-mdx": () => import("./../../../src/components/ui/Post.tsx?__contentFilePath=/vercel/path0/posts/breeze.mdx" /* webpackChunkName: "component---src-components-ui-post-tsx-content-file-path-posts-breeze-mdx" */),
  "component---src-components-ui-post-tsx-content-file-path-posts-governance-and-trust-mdx": () => import("./../../../src/components/ui/Post.tsx?__contentFilePath=/vercel/path0/posts/governance-and-trust.mdx" /* webpackChunkName: "component---src-components-ui-post-tsx-content-file-path-posts-governance-and-trust-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

